<template>
  <div class="view-container">
    <div class="card">
      <div class="card-header card-header-divider">
        <div class="card-title">Эксперты</div>
        <div class="tools">
          <router-link class="btn btn-accent" to="/expert/create"
            >Добавить эксперта</router-link
          >
        </div>
      </div>
      <div class="card-body">
        <experts-table />
      </div>
    </div>
  </div>
</template>

<script>
import ExpertsTable from "../../feature/partners/tables/ExpertsTable.vue";
import useCheckRights from "../../mixins/useCheckRights";
export default {
  components: { ExpertsTable },
  name: "experts-page",
  setup() {
    const { getRight } = useCheckRights();

    return {
      getRight,
    };
  },
};
</script>

<style>
</style>